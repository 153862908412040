console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttps://monk.com.au ', 'padding: 5px 13px;');

import './skip-link-focus-fix';
import './smoothscroll';
import './navigation';
import './scrollyclasses';
import './viewport-height';
import './fw-video';
//import './sliders';
import './accordions';
import './fancybox';
import './gsapanims';
import './typer';

jQuery(function ($) {

	//page
	var $hamburger = $(".hamburger"),
		$site = $("body"),
		$menu = $(".main-navigation"),
		$menuitems = $(".menu-item"),
		$screenOverlay = $(".screen-overlay");

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Mobile Menu
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	$hamburger.on("click", function () {
		$hamburger.toggleClass("is-active");
		$site.toggleClass("menu-open");
		// if ( $('body').hasClass('menu-open') ) {
		// 	$('#mobile-menu .label').text('Close')
		// } else {
		// 	$('#mobile-menu .label').text('Menu')
		// }
	});

	//close menu with an outside click (basically anywhere on .site-content)
	function closeMenu() {
		$site.removeClass("menu-open");
		$menu.removeClass("toggled");
		$menuitems.removeClass('toggled-on');
		$hamburger.removeClass("is-active");
		
	}

	$screenOverlay.on('click', closeMenu);

	$( ".site-footer .menu" ).append( $( ".contact-details" ) );

	$(document).on('keydown', function (e) {
		if (e.which == 27) {
			closeMenu();
		}
	});
	$("#masthead #menu-item-418 .sub-menu").prepend($(".about"));
	$("#masthead #menu-item-418 .sub-menu").append("<h5 class='main-title'>About</h5>");
	$("#masthead #menu-item-419 .sub-menu").prepend($(".cyber"));
	$("#masthead #menu-item-419 .sub-menu").append("<h5 class='main-title'>Cyber Security</h5>"); 
	$("#masthead #menu-item-420 .sub-menu").prepend($(".enterprise"));
	$("#masthead #menu-item-420 .sub-menu").append("<h5 class='main-title'>Enterprise</h5>"); 
	$("#masthead #menu-item-421 .sub-menu").prepend($(".smb"));
	$("#masthead #menu-item-421 .sub-menu").append("<h5 class='main-title'>SMB</h5>");

	$( "#masthead .menu-item-has-children" ).hover( 
		function() {	
			$(".screen-overlay").css("visibility", "visible").css("opacity", "1");

		}
	);

	$( "#masthead .menu-main-container" ).hover( 
		function() {

		}, function() {
			$(".screen-overlay").css("visibility", "hidden").css("opacity", "0");
		}
	);

	$("#masthead .menu-item-has-children a").on("click", function () {
		$(this).find($(".sub-menu")).fadeIn();
	});

	$(".close, .sub-menu a").on("click", function () {
		//$(".sub-menu").css('top', '-1000px').delay(1000).css('top', '100px');
	});


	$("#ticker").typer({
		strings: [
		  "data security.",
		  "managed services.",
		  "cloud solutions.",
		  "backup strategies."
		]
	  });

	/*
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	Animate elements in on scroll
	━━━ ⋅𖥔⋅ ━━✶━━ ⋅𖥔⋅ ━━━
	-ˋˏ *.·:·.⟐.·:·.* ˎˊ-
	*/
	// $('.animate').on('inview', function (event, isInView) {
	// 	if (isInView) {
	// 		$(this).addClass('fadein');
	// 	}
	// });
	// AOS.init();



});